<template>
<v-row no-gutters>      
    <v-col>
    <div id="leavelist">
                
        <!-- list filters -->

        <v-card
        tile
        :flat="$vuetify.breakpoint.mdAndDown"
        :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-t-lg'" >
        <v-toolbar
            v-if="!$vuetify.breakpoint.smAndDown"
            :color="$vuetify.breakpoint.mdAndDown ? 'transparent' : 'transparent'"
            flat
            :height="$vuetify.breakpoint.smAndDown ? 57 : 87"
        >
            <v-toolbar-title class="text-h6 font-weight-medium">
            <v-icon large left>
                {{ icons.mdiViewList }}
            </v-icon>
            İzin Taleplerim
            </v-toolbar-title>    
            <v-spacer></v-spacer>   
            <v-btn
            class="text-capitalize ms-3 rounded-l-pill rounded-r-0 mr-n4 bg-gradient-primary white--text"
            depressed
            x-large
            @click="addLeave()"
            @mouseenter="activeHover = true" @mouseleave="activeHover = false"
            >
            <v-icon class="mr-2">{{ icons.mdiPlus }}</v-icon>
            <span>İzin Ekle</span>
            <span  v-if="activeHover"> <img src="@/assets/images/misc/tukas-yaprak.png" width="20" > </span>   
            </v-btn>              
        </v-toolbar>

        <v-row>
            <v-col
                cols="12"
                sm="2"
                class="ma-2"
            >
            <v-select
            v-model="selectedYear"
            :items="years"
            label="Yıl Seçiniz"
            @change="fetchLeaves"
            ></v-select>

            </v-col>

            <v-col
                cols="12"
                sm="3"
                class="ma-2"
            >
            <v-select
            v-model="selectedMonth"
            :items="months"
            label="Ay Seçiniz"
            @change="fetchLeaves"
            ></v-select>
            </v-col>
            </v-row>

        <!-- table -->
        <v-data-table
            
            :headers="headers"
            :items="leaves"
            :items-per-page="15"
            fixed-header                      
            :loading="loading"
        >
            <!-- Text -->

            <template v-slot:item.LEAVEDESC="{ item }">
            <v-chip link outlined small :color="getColor(item.COLOR)">
                {{ item.LEAVEDESC }}
            </v-chip>
            </template>
            <template v-slot:item.CONFIRMSTAT="{ item }">
            <v-chip link outlined small :color="getStatusColor(item.CONFIRMSTAT)">
                {{ item.CONFIRMSTAT }}
            </v-chip>
            </template>
         <!--    <template #[`item.actions`]="{ item }">

            <v-tooltip bottom color="error">
                <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="item.CONFIRMSTAT === 'Bekliyor'" depressed color="error" x-small plain v-bind="attrs" @click="leaveDelete(item)" v-on="on">
                    <v-icon small>
                    {{ icons.mdiDelete }}
                    </v-icon>
                </v-btn>
                </template>
                <span>İzin Sil</span>
            </v-tooltip>
            </template> -->
        </v-data-table>
        </v-card>
    </div>
    </v-col>
</v-row>
  
  </template>
  
  <script>
  import store from '@/store'
  import VSnackbars from 'v-snackbars'
import { ref, watch } from '@vue/composition-api'
import router from '@/router'
import {
  mdiClipboardList,
  mdiClose,
  mdiDownload,
  mdiEye,
  mdiFilter,
  mdiFilterOff,
  mdiOrderNumericAscending,
  mdiPlusThick,
  mdiRefresh,
  mdiSend,mdiViewList,
  mdiDelete
} from '@mdi/js'
  export default {

    components: {   
    VSnackbars, 
  },
    data() {
      return {
        selectedYear: new Date().getFullYear(),
        selectedMonth: (new Date().getMonth() + 1).toString().padStart(2, '0'), // 01-12
        years: [],
        months: [
          { text: 'Ocak', value: '01' },
          { text: 'Şubat', value: '02' },
          { text: 'Mart', value: '03' },
          { text: 'Nisan', value: '04' },
          { text: 'Mayıs', value: '05' },
          { text: 'Haziran', value: '06' },
          { text: 'Temmuz', value: '07' },
          { text: 'Ağustos', value: '08' },
          { text: 'Eylül', value: '09' },
          { text: 'Ekim', value: '10' },
          { text: 'Kasım', value: '11' },
          { text: 'Aralık', value: '12' },
        ],
        leaves: [],
        loading: false ,// Yükleniyor durumu
        activeHover : false,       
        headers: [
        { text: 'İzin Açıklaması', value: 'LEAVENUM' },
        { text: 'Başlangıç Tarihi', value: 'FIRSTDATEDT' },
        { text: 'Bitiş Tarihi', value: 'LASTDATEDT' },
        { text: 'Toplam Gün', value: 'TOTLEAVEDAY' },
        { text: 'İzin Tipi', value: 'LEAVEDESC' },
        { text: 'Durum', value: 'CONFIRMSTAT' },
       /*  {
        text: 'İşlemler',
        value: 'actions',
        align: 'right',
        sortable: false,
      }, */
      ],
        icons: {
        mdiEye,
        mdiClose,
        mdiClipboardList,
        mdiOrderNumericAscending,
        mdiFilter,
        mdiDownload,
        mdiSend,
        mdiFilterOff,
        mdiRefresh,
        mdiPlusThick,mdiViewList,mdiDelete
      },
      };
    },
    methods: {
    async fetchLeaves() {
        debugger
        this.loading = true; // Yükleniyor durumunu başlat
        const year = this.selectedYear;
        const month = this.selectedMonth;

        const response = await store.dispatch('postMethod', {
        method: 'getPersLeaves',
        peryear: year,
        permonth: month
      });
      debugger
         this.loading = false; // Yükleniyor durumunu durdur
      if (response && response.detail ) {
        debugger
        if (response.error === 0) {
            this.leaves = response.detail[1].LEAVES;
            console.log("responsee",this.leaves)
          } else {
            this.leaves = [];
            console.error(response.msg);
          }
      }
      },

    getColor(color) {
      // Burada COLOR değerine göre uygun rengi döndür
      const colorMap = {
        info: '#2196F3', // Mavi
        success: '#4CAF50', // Yeşil
        error: '#F44336', // Kırmızı
        warning: '#FF9800', // Turuncu
      };
      return colorMap[color] || '#B0BEC5'; // Varsayılan gri
    },

    getStatusColor(status) {
      const statusColorMap = {
        Bekliyor: '#FF9800',
        Onaylandı: '#4CAF50',
        Reddedildi: '#F44336',
      };
      return statusColorMap[status] || 'grey'; // Varsayılan gri
    },
    addLeave(){
      router.push({ name: 'add-leave' })
    },

   

    },
    mounted() {
      // Yıllar listesini doldurun
      this.years = Array.from({ length: 10 }, (v, i) => new Date().getFullYear() - i);
      this.fetchLeaves(); // İlk yüklemede izinleri al
    }
  };
  </script>
  
  <style scoped>
  /* Stil ayarlamaları buraya eklenebilir */
  </style>
  